import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      Aryaf: "Aryaf",
      Home: "Home",
      Wishlist: "Wishlist",
      Terms: "Terms & Conditions",
      AboutUs: "About us",
      ContactUs: "Contact ud",
      Search: "Search",
      All: "All",
      AddCart: "Add To Cart",
      RemoveCart: "Remove From Cart",
      AddWishlist: "Add to wishlist",
      RemoveWishlist: "Remove from wishlist",
      Qnt: "Qnt",
      subPrice: "Sub Price",
      DeliveryCost: "Delivey Cost",
      GrandTotal: "Grand Total",
      ContinueToPayment: "Continue to payment",
      Shipping: "Shipping",
      PriceDetails: "Price Details",
      Items: "Items",
      Applly: "Apply",
      Voucher: "Voucer",
      ContinueToPay: "Continue to pay",
      Knet: "K-net",
      Cash: "Cash",
      ThankYou: "Thank you",
      placedOrder: "You order has been place successfuly",
      confirmEmailSent: "A confirmation has been sent to your email",
      OrderNo: "Order Number",
      Date: "Date",
      PaymentMethod: "Payment Method",
      OrderDetails: "Order Details",
      Products: "Products",
      Sorry: "Sorry",
      ErrMsg: "Sorry,There something went wrong with the payment",
      tryAgian: "Please Try Again",
      OrderNumber: "Order Number",
      Shop: "Shop",
      Cart: "Cart",
      Profile: "Profile",
      checkout: "Checkout",
      total: "Total",
      KWD: "KWD",
      phone: "Phone Number",
      area: "Area",
      block: "Block",
      street: "Street",
      house: "House",
      ave: "Ave",
      continue: "countinue",
      username: "username",
      password: "password",
      Signin: "Sign in",
      noAccount: "Don't have an account? ",
      ForgotPass: "Forgot Password",
      loginGuest: "Login as Guest",
      email: "Email (Optional)",
      createAccount: "Create an Account",
      register: "Register",
      submit: "Submit",
      changePass: "Change your Password",
      currentPass: "Current Password",
      newPass: "New Password",
      confirmPass: "Confirm New Password",
      logout: "Log out",
      new: "NEW",
      trueStatus: "True",
      falseStatus: "False",
      description: "Details and product description",
      firstName: "First Name",
      lastName: "Last Name",
      loading: "Loading",
      empty: "Your cart is Empty",
      Sun: "Sun",
      Sat: "Sat",
      Mon: "Mon",
      Thu: "Thu",
      Wed: "Wed",
      Tue: "Tue",
      Fri: "Fri",
      EN: "عربي",
      hasAccount: "Do you have an account",
      Categories: "Categories",
      Filter_By: "Filter By",
      maylike: "You may also like",
      Appartment: "Appartment",
      FloarNo: "Floar No.",
      Note: "Note",
      chooseUsername: "Please choose a username.",
      choosePass: "Please choose a password.",
      fillFirstName: "Please fill your first name.",
      fillLastName: "Please fill your last name.",
      soldOut: "Sold Out",
      OnSale: "On Sale",
      Aboutus: "About us",
      COME_TO_KNOW_US: "COME TO KNOW US",
      address: "Address",
      Contactـdetail: "Contact details",
      COMEـHAVEـAـLOOK: "COME HAVE A LOOK",
      contact: "Contact",
      working_hours: "Working Hourse",
      Rights_Reserved: "All Rights Reserved",
      about: "ABOUT",
      Contactus: "Contact us",
      pages: "USEFUL LINKS",
      FOLLOW_US: "FOLLOW US ON",
      Best_Selling: "Best Selling",
      New_Arrivals: "New Arrivals",
      Featured: "Featured",
      productAdded: "Product has been added succesfuly",
      productDeleted: "Product has been removed from cart",
      voucher: "Voucher",
      Apply: "Apply",
      outOfSotckMassage: "Sorry, Some items in your cart are our of stock",
      select_gov: "Select Governorate",
      gov: "Governorate",
      select_area: "Select Area",
      beforePrice: "Before Discount",
      discount: "Discount",
      phoneValide: "Phone number must be at least 8 digits long.",
    },
  },
  ar: {
    translation: {
      Aryaf: "أرياف",
      Home: "الرئيسيه",
      Wishlist: "المفضله",
      Terms: "الشروط والاحكام",
      AboutUs: "من نحن",
      ContactUs: "تواصل معنا",
      Search: "البحث",
      All: "الكل",
      AddCart: "اضافه للسله",
      OrderNumber: "رقم الطلب",
      RemoveCart: "ازالة من السله",
      AddWishlist: "اضافه الى المفضله",
      RemoveWishlist: "ازاله المفضله",
      Qnt: "الكميه",
      subPrice: "اجمالي القيمه",
      DeliveryCost: "التوصيل",
      GrandTotal: "المجموع الكلي",
      ContinueToPayment: "المتابعه",
      Shipping: "العنوان",
      PriceDetails: "تفاصيل المبلغ",
      Items: "قطع",
      Applly: "تنفيذ",
      Voucher: "كوبون الخصم",
      ContinueToPay: "المتابعه للدفع",
      Knet: "كي نت",
      Cash: "نقدي",
      ThankYou: "شكرا",
      placedOrder: "تم تقديم الطلب بنجاح",
      confirmEmailSent: "تم ارسال بريد الكتروني بالتفاصيل",
      OrderNo: "رقم الطلب",
      Date: "التاريخ",
      PaymentMethod: "طريقة الدفع",
      OrderDetails: "تفاصيل الطلب",
      Products: "المنتجات",
      Sorry: "نأسف",
      ErrMsg: "حدث خلل في عملية الدفع",
      tryAgian: "الرجاء اعاده المحاول",
      Shop: "السوق",
      Cart: "السلة",
      Profile: "الملف الشخصي",
      checkout: "الدفع",
      total: "المجموع",
      KWD: "د.ك",
      phone: "رقم الهاتف",
      area: "المنطقة",
      block: "القطعة",
      street: "الشارع",
      house: "المنزل",
      ave: "الجادة",
      continue: "استمرار",
      username: "إسم المستخدم",
      password: "كلمة المرور",
      Signin: "تسجيل الدخول",
      noAccount: "لا تملك حساب؟ سجل هنا",
      ForgotPass: "نسيت كلمة المرور",
      loginGuest: "الدخول كـ ضيف",
      email: "البريد الالكتروني (اختياري)",
      createAccount: "إنشاء حساب جديد",
      register: "تسجيل",
      submit: "إعتماد",
      changePass: "تغير كلمة المرور",
      currentPass: "كلمة السر الحالية",
      newPass: "كلمة السر الجديدة",
      confirmPass: "تأكيد كلمة المرور",
      logout: "تسجيل خروج",
      new: "جديد",
      trueStatus: "فعال",
      falseStatus: "انتهى",
      description: "وصف",
      firstName: "الاسم الاول",
      lastName: "الاسم العائلة",
      loading: "جاري التحميل",
      empty: " السلة فارغه",
      backShop: "تسوق",
      Sun: "احد",
      Sat: "سبت",
      Mon: "اثنين",
      Thu: "خميس",
      Wed: "اربعاء",
      Tue: "ثلاثاد",
      Fri: " جمعه",
      EN: "EN",
      hasAccount: "هل لديك حساب",
      Categories: "القوائم الرئيسية",
      Filter_By: "البحث حسب",
      maylike: "ربما يعجبك ايضا",
      Appartment: "شقة",
      FloarNo: "الدور",
      Note: "ملاحظه",
      chooseUsername: "الرجاء اختيار اسم المستخدم",
      choosePass: "الرجاء اختيار كلمة المرور",
      fillFirstName: "الرجاء كتابة الاسم الاول",
      fillLastName: "الرجاء كتابة الاسم العائله",
      soldOut: "نفذت",
      OnSale: "عرض",
      Aboutus: "من نحن",
      COME_TO_KNOW_US: "اعرف المزيد عنا",
      address: "العنوان",
      Contactـdetail: "معلومات التواصل",
      COMEـHAVEـAـLOOK: "تواصل معنا",
      contact: "التواصل",
      working_hours: "ساعات العمل",
      Rights_Reserved: "جميع الحقوق محفوظة",
      about: "معلومات",
      Contactus: "تواصل معنا",
      pages: "روابط تهمك",
      FOLLOW_US: "تابعنا",
      Best_Selling: "افضل المبيعات",
      New_Arrivals: "وصل حديثا",
      Featured: "المميز",
      productAdded: "تم اضافة المنتج للسله",
      productDeleted: "تم ازاله المنتج من السله",
      voucher: "قسيمه",
      Apply: "تاكيد",
      outOfSotckMassage: "نأسف، بعض المنتجات في السله قد نفذت",
      select_gov: "اختر المحافظه",
      gov: "المحافظه",
      select_area: "اختر المنطقه",
      beforePrice: "قبل الخصم",
      discount: "الخصم",
      phoneValide: "أدخل رقم هاتف صالح",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "ar",
    lng: localStorage.getItem("language"),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
